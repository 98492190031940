<template>
    <ValidationObserver
        ref="observer"
        v-slot="{ invalid }"
        tag="div"
    >
        <transition :enter-active-class="$style.modalEnterActive" :leave-active-class="$style.modalLeaveActive">
            <div v-if="modal.status.show" :class="$style.modal" @click.self="close()">
                <div :class="$style.modal__dialog" @click.self="close()">
                    <transition :enter-active-class="$style.modalEnterActive" :leave-active-class="$style.modalLeaveActive">
                        <div :class="$style.modal__content">
                            <div :class="$style.modal__content__header">
                                <template v-if="(!loader && !modal.status.finished) || modal.status.error">
                                    <button type="button" @click.self="close()"></button>
                                </template>
                            </div>
                            <div :class="$style.modal__content__body">
                                <template v-if="modal.status.error">
                                    {{ modal.data.message.error }}
                                </template>
                                <template v-else-if="modal.status.finished">
                                    {{ modal.data.message.end }}
                                </template>
                                <template v-else-if="!loader">
                                    <template v-if="modal.form">
                                        <div :class="$style.form">
                                            <p v-if="modal.data.message.start" :class="$style.inputMessage">
                                                {{ modal.data.message.start }}
                                            </p>
                                            <template v-for="(data, index) in device.form[modal.form]">
                                                <h2 :key="`head${index}`" :class="$style.inputHead">
                                                    {{ device.field[data.field].head }}
                                                </h2>
                                                <div :key="`div${index}`">
                                                    <Input
                                                        :data="data"
                                                        :flag-validated-immediate="flagValidatedImmediate"
                                                        :input="device.readData[data.field]"
                                                        @input="device.input[data.field] = $event"
                                                    />
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                    <template v-else>
                                        {{ modal.data.message.start }}
                                    </template>
                                </template>
                                <template v-else>
                                    <Loader
                                        :loader="loader"
                                    />
                                </template>
                            </div>
                            <div :class="$style.modal__content__footer">
                                <template v-if="modal.status.error">
                                    <Button
                                        class-name="buttonOvalCancel"
                                        :text="modal.data.button.error"
                                        type="modal"
                                        @action="close()"
                                    />
                                </template>
                                <template v-else-if="modal.status.finished">
                                    <Button
                                        class-name="buttonOvalPrimary"
                                        :text="modal.data.button.finished"
                                        type="modal"
                                        @action="action()"
                                    />
                                </template>
                                <template v-else-if="!loader">
                                    <Button
                                        class-name="buttonOvalCancel"
                                        :text="modal.data.button.cancel"
                                        type="modal"
                                        @action="close()"
                                    />
                                    <Button
                                        v-if="modal.data.button.primary"
                                        :disabled="invalid"
                                        class-name="buttonOvalPrimary"
                                        :text="modal.data.button.primary"
                                        type="modal"
                                        @action="action()"
                                    />
                                    <Button
                                        v-if="modal.data.button.other"
                                        class-name="buttonOvalOther"
                                        :text="modal.data.button.other"
                                        type="modal"
                                        @action="action()"
                                    />
                                    <Button
                                        v-if="modal.data.button.delete"
                                        class-name="buttonOvalDelete"
                                        :text="modal.data.button.delete"
                                        type="modal"
                                        @action="action()"
                                    />
                                </template>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </transition>
        <transition :enter-active-class="$style.modalBackdropEnterActive" :leave-active-class="$style.modalBackdropLeaveActive">
            <div v-if="modal.status.show" :class="$style.modalBackdrop"></div>
        </transition>
    </ValidationObserver>
</template>

<script>
    "use strict";

    import { ValidationObserver } from "vee-validate";

    import field from "@/settings/field.js";
    import form from "@/settings/form.js";

    import Button from "@/components/Button.vue";
    import Input from "@/components/Input.vue";
    import Loader from "@/components/Loader.vue";

    export default {
        "components": {
            ValidationObserver,
            Button,
            Input,
            Loader,
        },
        "props": {
            "modal": {
                "type": Object,
                "required": true,
            },
        },
        data() {
            return {
                "device": {
                    "input": {},
                    "field": field,
                    "flagValidatedImmediate": false,
                    "form": form,
                    "readData": [],
                },
                "invalid": true,
                "loader": false,
            };
        },
        "computed": {
            flagValidatedImmediate() {
                return this.modal.editMode === "create" ? true : false;
            },
        },
        "watch": {
            "modal": {
                "handler": function() {
                    this.loader = this.modal.loader;
                },
                "deep": true,
            },
        },
        "methods": {
            action() {
                this.loader = true;
                if (this.modal.status.finished) {
                    this.modal.callback.end();
                    this.loader = false;
                }
                else {
                    this.modal.callback.start(this.device.input);
                }
                if (this.modal.status.finished || this.modal.status.error) {
                    this.loader = false;
                }
            },
            close() {
                if ((!this.loader && !this.modal.status.finished) || this.modal.status.error) {
                    this.loader = false;
                    this.modal.status.show = false;
                    this.modal.status.finished = false;
                    this.modal.status.error = false;
                }
            },
        },
    };
</script>

<style lang="scss" module>
    @mixin modal-base {
        height: 100%;
        left: 0;
        overflow: hidden;
        position: fixed;
        top: 0;
        width: 100%;
    }

    .modal {
        @include modal-base;
        z-index: 9999;
        @include sp {
            padding: 0 24px;
        }
        &__dialog {
            align-items: center;
            display: flex;
            height: 100%;
            margin: auto;
            width: 528px;
            @include sp {
                width: 100%;
            }
        }
        &__content {
            background-color: #fff;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            width: 100%;
            &__header {
                background: #ccc;
                display: flex;
                justify-content: flex-end;
                margin-bottom: 32px;
                min-height: 40px;
                @include sp {
                    margin-bottom: 24px;
                }
                button {
                    cursor: pointer;
                    height: 48px;
                    position: relative;
                    width: 48px;
                    &::before {
                        background: $cancel-color;
                        content: "";
                        display: block;
                        height: 36px;
                        left: 23px;
                        position: absolute;
                        top: 6px;
                        transform: rotate(45deg);
                        width: 2px;
                    }
                    &::after {
                        background: $cancel-color;
                        content: "";
                        display: block;
                        height: 36px;
                        position: absolute;
                        right: 23px;
                        top: 6px;
                        transform: rotate(-45deg);
                        width: 2px;
                    }
                }
            }
            &__body {
                line-height: 1.6;
                font-size: 21px;
                padding: 16px;
                text-align: center;
                @include sp {
                    font-size: 18px;
                    padding: 8px 16px;
                }
            }
            &__footer {
                align-items: center;
                display: flex;
                justify-content: center;
                padding: 32px;
                @include sp {
                    padding: 24px;
                }
                > button {
                    @include sp {
                        margin-left: 0;
                    }
                    &:not(:last-child) {
                        @include sp {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .form {
        font-size: 16px;
        padding: 0 24px 16px;
        text-align: left;
        @include sp {
            padding: 0 8px;
        }
    }

    .inputMessage {
        font-size: 16px;
    }

    .inputHead {
        font-size: 18px !important;
        margin: 20px 0 4px !important;
        &:first-child {
            margin-top: 0 !important;
        }
        @include sp {
            font-size: 16px !important;
        }
    }

    .modalEnterActive {
        animation: modal .6s ease-out;
    }

    .modalLeaveActive {
        animation: modal .6s ease-in reverse;
    }

    @keyframes modal {
        0% {
            opacity: 0;
            top: -24px;
        }
        50% {
            opacity: 0;
            top: -24px;
        }
        100% {
            opacity: 1;
            top: 0;
        }
    }

    .modalBackdrop {
        @include modal-base;
        background: rgba($color: #000, $alpha: .5);
        z-index: 9998;
    }

    .modalBackdropEnterActive {
        animation: modal-backdrop-enter .3s ease-out;
    }

    .modalBackdropLeaveActive {
        animation: modal-backdrop-leave .6s ease-in reverse;
    }

    @keyframes modal-backdrop-enter {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes modal-backdrop-leave {
        0% {
            opacity: 0;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 1;
        }
    }
</style>
