<template>
    <Button
        v-if="module.use"
        class-name="buttonTheme"
        :text="buttonText"
        @action="getAddress()"
    />
</template>

<script>
    "use strict";

    import Button from "@/components/Button.vue";

    import axios from "@/mixins/axios.js";

    export default {
        "components": {
            Button,
        },
        "mixins": [
            axios,
        ],
        "props": {
            "buttonText": {
                "type": String,
                "required": false,
                "default": "住所取得",
            },
            "errorMessage": {
                "type": String,
                "required": false,
                "default": "住所の取得に失敗しました。郵便番号をご確認の上、もう一度ご入力ください。",
            },
            "zipcode": {
                "type": String,
                "required": true,
            },
        },
        data() {
            return {
                "module": this.$store.getters.getModules.zipAddress,
            };
        },
        "methods": {
            getAddress() {
                this.$_getAddress(this.zipcode).then(ressponse => {
                    let address = "";
                    let zipcode = "";
                    if (ressponse.data.data) {
                        address = ressponse.data.data.fullAddress;
                        zipcode = this.zipcode;
                    }
                    else {
                        alert(this.errorMessage);
                    }
                    this.$emit("address", address);
                    this.$emit("zipcode", zipcode);
                });
            },
        },
    };
</script>
