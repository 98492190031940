"use strict";

import firebase from "firebase";

export default {
    "methods": {
        // 画像保存
        $_fileUpload(input) {
            const process = [];
            for (const index in input.file) {
                const file = input.file[index].data;
                const fileName = input.fileName[index];
                if (file && fileName) {
                    const storage = firebase.storage();
                    const storageRef = storage.ref().child(fileName);
                    process.push(storageRef.put(file));
                }
            }
            if (process.length > 0) {
                return Promise.all(process).then(() => {
                    console.log("then: fileUpload");
                }).catch(() => {
                    console.error("catch: fileUpload");
                });
            }
        },
        // 画像取得
        $_fileDownload(input) {
            const filePath = [];
            const process = [];
            for (const index in input.fileName) {
                const fileName = input.fileName[index];
                if (fileName) {
                    const storage = firebase.storage();
                    const storageRef = storage.ref().child(fileName);
                    process.push(
                        storageRef.getDownloadURL().then(response => {
                            filePath[index] = response;
                        })
                    );
                }
            }
            return Promise.all(process).then(() => {
                return filePath;
            }).catch(() => {
                console.error("$_fileDownload Error");
            });
        },
        // 画像削除
        $_fileDelete(input, status) {
            const process = [];
            for (const index in input.file) {
                const file = input.file[index].data;
                const fileName = input.fileName[index];
                const deleteFlag = status !== "all" ? input.file[index].deleteFlag : "1";
                if (!file && fileName && deleteFlag === "1") {
                    const storage = firebase.storage();
                    const storageRef = storage.ref().child(fileName);
                    process.push(storageRef.delete());
                }
            }
            if (process.length > 0) {
                return Promise.all(process).then(() => {
                    console.log("then: fileDelete");
                }).catch(() => {
                    console.error("catch: fileDelete");
                });
            }
        },
    },
};
