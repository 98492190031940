"use strict";

export default {
    data() {
        return {
            "modal": {
                "data": "",
                "loader": false,
                "status": {
                    "error": false,
                    "finished": false,
                    "show": false,
                },
            },
            "modalReset": {
                "callback": {
                    "end": null,
                    "start": null,
                },
                "data": {},
            },
        };
    },
    "methods": {
        $_modalReset(type) {
            const title = this.management.title;
            const textset = this.textset[this.management.textset];
            const modalReset = this.modalReset;
            const modalResetData = {
                "create": {
                    "message": {
                        "start": `${title}を${textset.create}しますか？`,
                        "end": `${textset.create}しました！`,
                        "error": `${title}の${textset.create}に失敗しました。`,
                    },
                    "button": {
                        "primary": `${title}を${textset.create}する`,
                        "cancel": "キャンセル",
                        "finished": `${title}一覧へ戻る`,
                        "error": "閉じる",
                    },
                },
                "update": {
                    "message": {
                        "start": `${title}を${textset.update}しますか？`,
                        "end": `${textset.update}しました！`,
                        "error": `${title}の${textset.update}に失敗しました。`,
                    },
                    "button": {
                        "primary": `${title}を${textset.update}する`,
                        "cancel": "キャンセル",
                        "finished": `${title}一覧へ戻る`,
                        "error": "閉じる",
                    },
                },
                "delete": {
                    "message": {
                        "start": `${title}を${textset.delete}しますか？`,
                        "end": `${textset.delete}しました！`,
                        "error": `${title}の${textset.delete}に失敗しました。`,
                    },
                    "button": {
                        "delete": `${title}を${textset.delete}する`,
                        "cancel": "キャンセル",
                        "finished": `${title}一覧へ戻る`,
                        "error": "閉じる",
                    },
                },
                "detail": {
                    "message": {
                        "start": `${title}を${textset.update}しますか？`,
                        "end": `${textset.update}しました！`,
                        "error": `${title}の${textset.update}に失敗しました。`,
                    },
                    "button": {
                        "primary": `${title}を${textset.update}する`,
                        "cancel": "キャンセル",
                        "finished": `${title}一覧へ戻る`,
                        "error": "閉じる",
                    },
                },
            };
            if (type) {
                modalReset.data = modalResetData[type];
            }
            return modalReset;
        },
        $_modalOpen(modal) {
            if (typeof(modal) === "object") {
                this.modal.data = modal.data;
                this.modal.callback = modal.callback;
                this.modal.form = modal.form ?? null;
                this.modal.editMode = modal.editMode ?? null;
            }
            this.modal.status.show = true;
            this.modal.status.finished = false;
            this.modal.status.error = false;
        },
        $_modalClose() {
            this.modal.status.show = false;
            this.modal.status.finished = false;
            this.modal.status.error = false;
        },
        $_modalFinished() {
            this.modal.status.show = true;
            this.modal.status.finished = true;
        },
        $_modalError(data) {
            if (typeof(data) === "object") {
                this.modal.data = {
                    "message": {
                        "error": data.message,
                    },
                    "button": {
                        "error": "閉じる",
                    },
                };
            }
            else if (data) {
                const title = this.management.title;
                const textset = this.textset[this.management.textset];
                this.modal.data = {
                    "message": {
                        "error": `${title}の${textset[data]}に失敗しました。`,
                    },
                    "button": {
                        "error": "閉じる",
                    },
                };
            }
            this.modal.status.show = true;
            this.modal.status.error = true;
        },
    },
};
