"use strict";

export default {
    // "selectDriver": [
    //     {
    //         "field": "driverName",
    //         "required": true,
    //     },
    // ],
    "inputReservationDatetime": [
        {
            "field": "reservationDate",
            "required": true,
        },
        {
            "field": "reservationTime",
            "required": true,
        },
    ],
};
