<template>
    <div>
        <template v-if="type === 'radio' || type === 'checkbox'">
            <template v-for="(data, index) in select">
                <input
                    :id="`${field}${index}`"
                    :key="`input${field}${index}`"
                    :name="field"
                    :value="data"
                    :type="type"
                    :checked="(index === 0 && type === 'radio') || input.includes(data) ? true : false"
                    :disabled="disabled"
                    @change="setValue($event.target)"
                >
                <label :key="`label${field}${index}`" :for="`${field}${index}`" >
                    {{ data }}
                </label>
            </template>
        </template>
        <template v-else>
            <ValidationProvider
                v-slot="{ errors }"
                :name="head"
                :rules="rules"
                :immediate="flagValidatedImmediate"
            >
                <template>
                    <template v-if="type === 'select'">
                        <select
                            :value="value"
                            :disabled="disabled"
                            @change="setValue($event.target)"
                        >
                            <option value="">
                                選択してください
                            </option>
                            <option
                                v-for="(data, index) in select"
                                :key="`option${index}`"
                                :value="data"
                            >
                                {{ data }}
                            </option>
                        </select>
                    </template>
                    <template v-else-if="type === 'textarea'">
                        <textarea
                            rows="5"
                            :value="value"
                            :disabled="disabled"
                            @input="setValue($event.target)"
                        >
                        </textarea>
                    </template>
                    <template v-else-if="field === 'postalCode'">
                        <div class="l-2-00-m--pc">
                            <div>
                                <input
                                    :value="value"
                                    :type="type"
                                    :disabled="disabled"
                                    @input="setValue($event.target)"
                                >
                            </div>
                            <div :class="$style.zipAddress">
                                <ZipAddress
                                    :zipcode="value"
                                    @address="setAddress($event)"
                                />
                            </div>
                        </div>
                    </template>
                    <template v-else-if="type === 'timestamp'">
                        <input
                            :value="value ? $_getDateTime(value.seconds * 1000).db : value"
                            type="text"
                            disabled
                        >
                    </template>
                    <template v-else>
                        <input
                            :value="value"
                            :type="type"
                            :disabled="disabled"
                            @input="setValue($event.target)"
                        >
                    </template>
                </template>
                <p
                    v-show="errors[0]"
                    class="errorMessage"
                >
                    {{ errors[0] }}
                </p>
            </ValidationProvider>
        </template>
    </div>
</template>

<script>
    "use strict";

    import firebase from "firebase";
    import { extend, ValidationProvider } from "vee-validate";
    import * as rules from "vee-validate/dist/rules";
    import ja from "vee-validate/dist/locale/ja.json";

    import validate from "@/validate.js";

    import field from "@/settings/field.js";

    import ZipAddress from "@/components/ZipAddress.vue";

    import datetime from "@/mixins/datetime.js";

    const firestore = firebase.firestore();

    for (const rule in rules) {
        extend(rule, {
            ...rules[rule],
            "message": ja.messages[rule],
        });
    }

    for (const key in validate) {
        extend(`${key}`, validate[key]);
    }

    export default {
        "components": {
            ValidationProvider,
            ZipAddress,
        },
        "mixins": [
            datetime,
        ],
        "props": {
            "data": {
                "type": Object,
                "required": true,
            },
            "disabled": {
                "type": Boolean,
                "required": false,
            },
            "flagValidatedImmediate": {
                "type": Boolean,
                "required": true,
            },
            "input": {
                "required": false,
                "default": "",
            },
            "undo": {
                "required": false,
                "default": "",
            },
        },
        data() {
            return {
                "field": "",
                "head": "",
                "rules": "",
                "select": [],
                "type": "",
                "undoValues": [],
                "value": "",
            };
        },
        "watch": {
            "input": function() {
                this.setData();
            },
            "undo": function() {
                const num = this.undoValues.length - 2;
                this.undoValues.pop();
                this.value = this.undoValues[num];
            },
        },
        created() {
            this.setData();
            if (this.type === "radio") {
                this.$emit("input", this.select[0]);
            }
        },
        "methods": {
            setData() {
                const fieldName = this.data.field;
                const fieldData = field[fieldName];
                const rules = fieldData.rules;
                this.field = fieldName;
                this.head = fieldData.head;
                this.rules = this.data.required ? rules ? `${rules}|required`: "required" : rules;
                this.select = fieldData.select;
                this.type = fieldData.type;
                this.value = JSON.parse(JSON.stringify(this.input));
                if (fieldData.collection) {
                    const collectionName = fieldData.collection.name;
                    const collectionKey = fieldData.collection.key;
                    const database = firestore.collection(collectionName);
                    database.get().then(response => {
                        const list = [];
                        response.forEach(doc => {
                            list.push(doc.data()[collectionKey]);
                        });
                        this.select = list;
                    });
                }
            },
            setValue(target) {
                if (this.type === "checkbox") {
                    if (target.checked) {
                        this.value.push(target.value);
                    }
                    else {
                        this.value = this.value.filter(item => item.match(target.value) === null);
                    }
                }
                else {
                    if (this.type === "number") {
                        this.value = target.value ? Number(target.value) : target.value;
                    }
                    else {
                        this.value = target.value;
                    }
                }
                if (this.undo) {
                    this.undoValues.push(target.value);
                }
                this.$emit("input", this.value);
            },
            setAddress(address) {
                this.$emit("address", address);
            },
        },
    };
</script>

<style lang="scss" module>
    .zipAddress {
        padding: 0 0 0 8px;
        text-align: left !important;
    }
</style>
