"use strict";

export default {
    "address": {
        "head": "住所",
        "type": "text",
        "rules": "",
        "class": "isWrap",
    },
    "closedDate": {
        "head": "休業日",
        "type": "date",
        "rules": "",
        "class": "",
    },
    "comment": {
        "head": "コメント",
        "type": "textarea",
        "rules": "",
        "class": "isWrap",
    },
    "driverName": {
        "head": "ドライバー",
        "type": "select",
        "class": "",
        "collection": {
            "name": "drivers",
            "key": "name",
        },
    },
    "endTime": {
        "head": "終了時間",
        "type": "time",
        "rules": "",
        "class": "",
    },
    "freeText": {
        "head": "備考",
        "type": "textarea",
        "rules": "",
        "class": "isWrap",
    },
    "gender": {
        "head": "性別",
        "type": "radio",
        "class": "",
        "select": ["男性", "女性", "その他"],
    },
    "image": {
        "head": "画像",
        "type": "file",
        "rules": "",
        "class": "",
    },
    "itemDescription": {
        "head": "商品説明",
        "type": "text",
        "rules": "",
        "class": "isWrap",
    },
    "itemName": {
        "head": "商品名",
        "type": "text",
        "rules": "",
        "class": "",
    },
    "itemNumber": {
        "head": "商品番号",
        "type": "text",
        "rules": "alpha_dash",
        "class": "",
    },
    "mailAddress": {
        "head": "メールアドレス",
        "type": "email",
        "rules": "",
        "class": "",
    },
    "memo": {
        "head": "メモ",
        "type": "textarea",
        "rules": "",
        "class": "isWrap",
    },
    "name": {
        "head": "ニックネーム",
        "type": "text",
        "rules": "",
        "class": "",
    },
    "nickname": {
        "head": "ニックネーム",
        "type": "text",
        "rules": "",
        "class": "",
    },
    "orderDatetime": {
        "head": "注文日",
        "type": "timestamp",
        "rules": "",
        "class": "isRight",
    },
    "password": {
        "head": "パスワード",
        "type": "password",
        "rules": "password",
        "class": "",
    },
    "paymentMethod": {
        "head": "支払い方法",
        "type": "select",
        "class": "",
        "select": ["クレジットカード", "銀行振込（前払い）", "代金引換"],
    },
    "postalCode": {
        "head": "郵便番号",
        "type": "text",
        "rules": "numeric|length:7",
        "class": "",
        "sort": "number",
        "connect": "address",
    },
    "price": {
        "head": "価格",
        "type": "number",
        "rules": "min_value:1|numeric",
        "class": "",
        "sort": "number",
        "unit": "円",
        "filter": "exact",
    },
    "receipt": {
        "head": "領収証",
        "type": "radio",
        "class": "",
        "select": ["なし", "あり"],
    },
    "receiptAddress": {
        "head": "宛名",
        "type": "text",
        "rules": "",
        "class": "",
    },
    "receiptDescription": {
        "head": "但し書き",
        "type": "text",
        "rules": "",
        "class": "",
    },
    "receiptInclude": {
        "head": "同梱",
        "type": "radio",
        "class": "",
        "select": ["お客様情報入力住所へ郵送", "商品へ同梱してお届け"],
    },
    "receptionDate": {
        "head": "預かり日",
        "type": "date",
        "rules": "",
        "class": "",
    },
    "reservationDate": {
        "head": "返却希望日",
        "type": "date",
        "rules": "",
        "class": "",
    },
    "reservationTime": {
        "head": "返却希望時間",
        "type": "time",
        "rules": "",
        "class": "",
    },
    "returnDatetime": {
        "head": "返却日時",
        "type": "timestamp",
        "rules": "",
        "class": "",
    },
    "sendAddress": {
        "head": "送り先住所",
        "type": "text",
        "rules": "",
        "class": "isWrap",
    },
    "sendName": {
        "head": "送り先氏名",
        "type": "text",
        "rules": "",
        "class": "",
    },
    "sendPostalCode": {
        "head": "送り先郵便番号",
        "type": "text",
        "rules": "numeric|length:7",
        "class": "",
        "sort": "number",
    },
    "sendTel": {
        "head": "送り先電話番号",
        "type": "text",
        "rules": "numeric",
        "class": "",
        "sort": "",
    },
    "startTime": {
        "head": "開始時間",
        "type": "time",
        "rules": "",
        "class": "",
    },
    "stock": {
        "head": "在庫数",
        "type": "number",
        "rules": "min_value:0|numeric",
        "class": "",
        "sort": "number",
        "unit": "個",
        "filter": "exact",
    },
    "tel": {
        "head": "電話番号",
        "type": "text",
        "rules": "numeric",
        "class": "",
    },
    "updateId": {
        "head": "ID",
        "type": "id",
        "rules": "",
        "class": "",
    },
    "userId": {
        "head": "お客様ID",
        "type": "id",
        "rules": "",
        "class": "",
    },
    "&nbsp": {
        "head": "",
        "type": "",
        "rules": "",
        "class": "isRight",
    },
};
