"use strict";

export default {
    "methods": {
        $_getDateTimeValue(data) {
            const date = data ? new Date(data) : new Date();
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const hour = date.getHours();
            const minute = date.getMinutes();
            const second = date.getSeconds();
            const day = date.getDate();
            const week = ["日", "月", "火", "水", "木", "金", "土"][date.getDay()];
            const zero = (number) => {
                return ("0" + number).slice(-2);
            };
            return {
                "date": date,
                "YYYY": year,
                "M": month,
                "D": day,
                "h": hour,
                "m": minute,
                "s": second,
                "w": week,
                "MM": zero(month),
                "DD": zero(day),
                "hh": zero(hour),
                "mm": zero(minute),
                "ss": zero(second),
            };
        },
        $_getDate(data) {
            const date = this.$_getDateTimeValue(data);
            return {
                "jp": `${date.YYYY}年${date.MM}月${date.DD}日`,
                "db": `${date.YYYY}-${date.MM}-${date.DD}`,
                "dot": `${date.YYYY}.${date.MM}.${date.DD}`,
                "slash": `${date.YYYY}/${date.MM}/${date.DD}`,
            };
        },
        $_getDateTime(data) {
            const date = this.$_getDateTimeValue(data);
            return {
                "jp": `${date.YYYY}年${date.MM}月${date.DD}日 ${date.hh}時${date.mm}分${date.ss}秒`,
                "db": `${date.YYYY}-${date.MM}-${date.DD} ${date.hh}:${date.mm}:${date.ss}`,
                "dot": `${date.YYYY}.${date.MM}.${date.DD} ${date.hh}:${date.mm}:${date.ss}`,
                "slash": `${date.YYYY}/${date.MM}/${date.DD} ${date.hh}:${date.mm}:${date.ss}`,
            };
        },
        $_getMonthly(data) {
            const date = this.$_getDateTimeValue(data);
            return {
                "startDate": `${date.YYYY}-${date.MM}-01`,
                "endDate": this.$_getDate(new Date(date.YYYY, date.MM, 0)).db,
            };
        },
        $_deadline(data) {
            // 時間の概念を消す
            const dateValue = {
                "today": `${this.$_getDate().db} 00:00:00`,
                "date": `${this.$_getDate(data.date).db} 00:00:00`,
            };
            const today = this.$_getDateTimeValue(dateValue.today).date;
            const date = this.$_getDateTimeValue(dateValue.date).date;
            const deadline = this.$_getDateTimeValue(dateValue.date).date;
            const holiday = data.holiday ? data.holiday : [];
            // const holiday = data.holiday ? data.holiday : [6, 0];
            let dayOffs = 0;

            // 期限を計算する（次の日から）
            deadline.setDate(date.getDate() + (data.workingDays + 1));

            // 期間を計算する
            const startTime = date.getTime();
            const endTime = deadline.getTime();
            const diff = endTime - startTime;
            const term = Math.floor(diff / (24 * 60 * 60 * 1000)) + 1;

            // 期間内の一週間の回数を計算して休日の数をかける
            const weeks = Math.floor(term / 7);
            dayOffs = weeks * holiday.length;

            // 残りの日数を計算する
            const dateWeek = date.getDay();
            const remainderDays = term % 7;
            if (remainderDays > 0) {
                for (let i = 0; i < remainderDays; i++) {
                    if (holiday.indexOf((dateWeek + i) % 7) !== -1) {
                        dayOffs++;
                    }
                }
            }

            // 期限に休日を足す
            deadline.setDate(deadline.getDate() + dayOffs);

            // 期限が休日の場合は次の日にずらす
            for (const i of holiday) {
                if (deadline.getDay() === i) {
                    deadline.setDate(deadline.getDate() + 1);
                }
            }

            // 期限を判定する
            if (today < deadline) {
                return true;
            }
            else {
                return false;
            }
        },
    },
};
