"use strict";

import axios from "axios";

export default {
    "methods": {
        // 郵便番号・住所検索API
        $_getAddress(zipcode) {
            return axios.get(
                "https://api.zipaddress.net/",
                {
                    "headers": {
                        "Content-Type": "application/json",
                    },
                    "params": {
                        "zipcode": zipcode,
                    },
                }
            );
        },
    },
};
